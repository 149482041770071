$(function() {
	setSectionWidth();
});

//リサイズされたときの処理
$(window).resize(function() {
	setSectionWidth();
});

function setSectionWidth(){
	var sectionArray = ['__concept', '__news', '__access'];
	for (var i = 0; i < sectionArray.length; i++) {
		var width = $(".section" + sectionArray[i] + " .slideInLeftWrap").width();
		$(".section" + sectionArray[i] + " .slideInLeftInner").css({width: width});

		width = $(".section" + sectionArray[i] + " .slideInRightWrap").width();
		$(".section" + sectionArray[i] + " .slideInRightInner").css({width: width});
	}

	var screenWidth = $('body').width();

	if (screenWidth < 980) {
		var sectionArray = ['__gallery', '__style'];

		for (var i = 0; i < sectionArray.length; i++) {
			var width = $(".section" + sectionArray[i] + " .slideInLeftWrap").width();
			$(".section" + sectionArray[i] + " .slideInLeftInner").css({width: width});

			width = $(".section" + sectionArray[i] + " .slideInRightWrap").width();
			$(".section" + sectionArray[i] + " .slideInRightInner").css({width: width});
		}

	} else {
		$(".section__gallery .slideInLeftInner").css({width: 420});
		var width = $('#sliderGallery').width();

		$(".section__style .slideInLeftInner").css({width: 420});
		var width = $('#sliderGallery').width();
	}
}
