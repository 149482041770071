$(function() {
	var bodyClass = $("body").attr('id');
	if (bodyClass !== 'home') return false;
	var images = document.getElementsByTagName('img'); // ページ内の画像取得
	var percent = document.getElementById('loadNum'); // パーセントのテキスト部分
	var loadingBg = $('#load'); // ローディング背景
	var imgCount = 0;
	var baseCount = 0;
	var current;

	// 画像の読み込み
	for (var i = 0; i < images.length; i++) {
		var img = new Image(); // 画像の作成
		// 画像読み込み完了したとき
		img.onload = function() {
			imgCount += 1;
		}
		// 画像読み込み失敗したとき
		img.onerror = function() {
			imgCount += 1;
		}
		img.src = images[i].src; // 画像にsrcを指定して読み込み開始
	};

	// ローディング処理
	var nowLoading = setInterval(function() {
		if(baseCount <= imgCount) { // baseCountがimgCountを追い抜かないようにする
			// 現在の読み込み具合のパーセントを取得
			current = Math.floor(baseCount / images.length * 100);
			// パーセント表示の書き換え
			percent.innerHTML = current;
			baseCount += 1;
			// 全て読み込んだ時
			// if(baseCount == images.length) {
			if(current === 100) {
				// ローディング要素の非表示
				setTimeout( function(){
					loadingBg.css({opacity: 0});
				}, 500 );
				setTimeout( function(){
					loadingBg.css({display: "none"});
				}, 1000 );
				// navの表示
				setTimeout( function(){
					$("#navTop, #headerTop").animate({
						opacity: "1",
						marginTop: "0"
					});
				}, 2500 );
				// snsの表示
				setTimeout( function(){
					$("#snsTop").animate({
						opacity: "1",
						left: "24px"
					});
				}, 3000 );
				// ローディングの終了
				clearInterval(nowLoading);
			}

		}
	}, 5);
});
