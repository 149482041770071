$(function() {
	var flgs = {
		"__concept": false,
		"__news": false,
		"__gallery": false,
		"__style": false,
		"__access": false,
	}
	$('#fullpage').fullpage({
		// anchors: ['__top', '__concept', '__gallery', '__style', '__news', '__access'], // ページのアンカー名を指定
		anchors: ['__top', '__concept', '__gallery', '__news', '__access'], // ページのアンカー名を指定
		menu: '#globalMenu', // グローバルメニューのID名
		scrollOverflow: true, //全画面よりコンテンツが多い場合スクロールバーを出すかどうか
		touchSensitivity: 5, //タッチデバイスでのセクション切替の強さの閾値
		normalScrollElements: '.nav-slider, .list-access, .sp-modal__wrap',
		normalScrollElementTouchThreshold: 3,
		recordHistory: false, // スクロールした時にブラウザに履歴を残さない
		scrollBar: true,
		autoScrolling: true,
		fitToSection: true,
		fitToSectionDelay: 0,
		afterRender: function(){},
		//セクションが読み込み終わりのイベント
		afterLoad: function (anchorLink, index) {
			if (anchorLink === '__top') {
				// ヘッダーのロゴを非表示
				$('#headerLogo').css({opacity: 0});

				setTimeout( function(){
					$('#topLogo').css({opacity: 1});
				}, 1800);
				setTimeout( function(){
					$('#topArrow').css({opacity: 1});
				}, 2100 );
			} else {
				// ヘッダーのロゴを表示
				$('#headerLogo').css({opacity: 1});

				// 各ページの表示設定
				if (flgs[anchorLink] === false)  {
					flgs[anchorLink] = true;
					if (anchorLink === '__access') {
						slideIn(anchorLink);
						setTimeout( function(){
							$('.section__access .slideInArrow').animate({
								opacity: 1,
							}, 800);
						}, 1800 );
					} else {
						slideIn(anchorLink);
					}
				}
			}

			// nav 下線バーの処理
			navAnchor(index);

			// sp用　メニューの下線処理
			$('#navSliderList a.nav-slider__link').removeClass('is-active');
			$('#navSliderList a.nav-slider__link[href="#' + anchorLink + '"]').addClass('is-active');

			// $.fn.fullpage.setAllowScrolling(true);
		},
		// セクションを離れた時のイベント
		onLeave: function (index, nextIndex, direction) {
			// nav 下線バーの処理
			navAnchor(nextIndex);

			// sp用　メニューの非表示処理
			$(".nav-slider").fadeOut();
			if ($("#menuTrigger").hasClass("is-active")) {
				$("#menuTrigger").removeClass("is-active");
			}

			// TOPから離れる場合はロゴと矢印を非表示にする
			if (index === 1) {
				$('#topLogo').css({opacity: 0});
				$('#topArrow').css({opacity: 0});

			}
		},
		// リサイズ後のイベント
		afterResize: function () {
			$.fn.fullpage.reBuild();
		}
	});
});


/**
 * グローバルナビ　アンカー移動
 * @param index fullpage.js リンクオブジェクト
 */
function navAnchor(index) {
	var width = $('.nav__list-item a').eq(index -1).width();
	var position = $('.nav__list-item a').eq(index -1).position();
	$('#globalMenuLine').animate({
		width: width,
		left: position.left
	},500);
}

function slideIn(anchorLink){
	$('.section' + anchorLink + ' .slideInLeft').animate({
		width: '100%',
	},800);
	setTimeout( function(){
		$('.section' + anchorLink + ' .slideInRight').animate({
			width: '100%',
		}, 800);
	}, 1200 );
}
