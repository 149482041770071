$(function() {
	var setGallery;
	var setStyle;

	nextArrow();
	setInterval(nextArrow, 4000);

	$('.arrowRightGallery').hover(function(){
		arrowRightGallery();
		setGallery = setInterval(arrowRightGallery, 4000);
	},function(){
		clearInterval(setGallery);
	});

	$('.arrowRightStyle').hover(function(){
		arrowRightStyle();
		setStyle = setInterval(arrowRightStyle, 4000);
	},function(){
		clearInterval(setStyle);
	});

});

function nextArrow() {
	$('.nextArrow').animate({
		top: '0',
		opacity: 1
	}, 1000).animate({
		top: '0',
		opacity: 1
	}, 500).animate({
		top: '26px',
		opacity: 0
	}, 1000).animate({
		top: '-26px',
		opacity: 0
	}, 1000);
}

function arrowRightGallery() {
	$('.arrowRightGallery').animate({
		left: '26px',
		opacity: 0
	}, 1000).animate({
		left: '-26px',
		opacity: 0
	}, 1000).animate({
		left: '0',
		opacity: 1
	}, 1000).animate({
		left: '0',
		opacity: 1
	}, 500);
}

function arrowRightStyle() {
	$('.arrowRightStyle').animate({
		left: '26px',
		opacity: 0
	}, 1000).animate({
		left: '-26px',
		opacity: 0
	}, 1000).animate({
		left: '0',
		opacity: 1
	}, 1000).animate({
		left: '0',
		opacity: 1
	}, 500);
}
