var swiperGallery = new Swiper('#sliderGallery', {
	slidesPerView: 2,
	// centeredSlides: true,
	spaceBetween: 30,
	height: 470
});

var swiperStyle = new Swiper('#sliderStyle', {
	slidesPerView: 3.3,
	// centeredSlides: true,
	spaceBetween: 30,
	height: 470
});
$('#sliderNextGallery').click(function() {
	swiperGallery.slideNext(500);
});

$('#sliderNextStyle').click(function() {
	swiperStyle.slideNext(500);
});
