$(function() {
	$("body").on("click", "#openModalConcept", function () {
		$("#conceptModal").fadeIn();
		// fullpage.jsのスクロールを無効化する
		$.fn.fullpage.setAllowScrolling(false);
	});

	$("body").on("click", "#openModalAccess", function () {
		$("#accessModal").fadeIn();
		// fullpage.jsのスクロールを無効化する
		$.fn.fullpage.setAllowScrolling(false);
	});

	$("body").on("click", ".spModalClose", function () {
		$(".sp-modal").fadeOut();
		// fullpage.jsのスクロールを有効化する
		$.fn.fullpage.setAllowScrolling(true);
	});
});
