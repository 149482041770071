$(function() {
	var bodyId = $("body").attr("id");
	$("#menuTrigger").click(function () {
		if ($(this).hasClass("is-active")) {
			$(".nav-slider").fadeOut();
			// fullpage.jsのスクロールを有効化する
			if (bodyId === "home") {
				$.fn.fullpage.setAllowScrolling(true);
			}
		} else {
			$(".nav-slider").fadeIn();
			// fullpage.jsのスクロールを無効化する
			if (bodyId === "home") {
				$.fn.fullpage.setAllowScrolling(false);
			}
		}
		$(this).toggleClass("is-active");
	});

	$("#navSliderList a").click(function () {
		if (bodyId === "home") {
			$.fn.fullpage.setAllowScrolling(true);
		}
	});
});
